import React from 'react'
import { Avatar, Box, Chip, Divider, Typography } from '@mui/material'
import { Cancel, CheckCircle, Help } from '@mui/icons-material'
import AvatarList from '../../Avatar/AvatarList'
import RoleChips from '../../RoleChips/RoleChips'

const UsersDisponibilities = props => {
  const { usersByDisponibility, seeMore, group, highlightedRole, handleRoleClick } = props

  const sortUsersByNumber = data => {
    const sortArray = arr => {
      return arr.sort((a, b) => {
        if (a.number === '' || a.number === null) return 1
        if (b.number === '' || b.number === null) return -1
        return a.number - b.number
      })
    }

    return {
      ok: sortArray(data.ok),
      notOk: sortArray(data.notOk),
      needConfirmation: sortArray(data.needConfirmation),
      noResponse: sortArray(data.noResponse),
    }
  }

  const attendances = sortUsersByNumber(usersByDisponibility)

  return (
    <Box sx={{ display: 'flex', mt: 1, flexDirection: 'column' }}>
      {!!attendances?.ok.length && (
        <>
          <Box sx={{ flex: 1 }}>
            <Divider>
              <Chip
                size="small"
                sx={{ color: '#66bb6a' }}
                avatar={
                  <Avatar sx={{ backgroundColor: '#66bb6a' }}>
                    <Typography sx={{ color: '#000', fontSize: 10 }}>{attendances?.ok.length}</Typography>
                  </Avatar>
                }
                label={attendances?.ok.length > 1 ? 'Présents' : 'Présent'}
              />
            </Divider>
            <AvatarList
              highlightedRole={highlightedRole}
              seeMore={seeMore}
              users={attendances?.ok}
              color="#66bb6a"
              title={attendances?.ok.length > 1 ? 'Présents' : 'Présent'}
              icon={<CheckCircle color="success" />}
              group={group}
            />
          </Box>
          <Box>
            <RoleChips
              highlightedRole={highlightedRole}
              handleRoleClick={handleRoleClick}
              usersByDisponibility={attendances}
            />
          </Box>
        </>
      )}
      {seeMore && !!attendances?.notOk.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#ff8f5ee6' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#ff8f5ee6' }}>
                  <Typography sx={{ color: '#000', fontSize: 10 }}>{attendances?.notOk.length}</Typography>
                </Avatar>
              }
              label={attendances?.notOk > 1 ? 'Absents' : 'Absent'}
            />
          </Divider>
          <AvatarList
            users={attendances?.notOk}
            color="#ff8f5ee6"
            title={attendances?.notOk.length > 1 ? 'Absents' : 'Absent'}
            icon={<Cancel color="#ff8f5ee6" />}
            group={group}
          />
        </Box>
      )}
      {seeMore && !!attendances?.needConfirmation.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#90caf9' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#90caf9' }}>
                  {' '}
                  <Typography sx={{ color: '#000', fontSize: 10 }}>{attendances?.needConfirmation.length}</Typography>
                </Avatar>
              }
              label={attendances?.needConfirmation > 1 ? 'Incertains' : 'Incertain'}
            />
          </Divider>
          <AvatarList
            users={attendances?.needConfirmation}
            color="#90caf9"
            title={attendances?.needConfirmation.length > 1 ? 'Incertains' : 'Incertain'}
            icon={<Help color="#90caf9" />}
            group={group}
          />
        </Box>
      )}
      {seeMore && !!attendances?.noResponse.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#d6c1abcc' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#d6c1abcc' }}>
                  {' '}
                  <Typography sx={{ color: '#000', fontSize: 10 }}>{attendances?.noResponse.length}</Typography>
                </Avatar>
              }
              label="Sans réponse"
            />
          </Divider>
          <AvatarList
            users={attendances?.noResponse}
            color="#d6c1abcc"
            title="Sans réponse"
            group={group}
            icon={<Help color="#d6c1abcc" />}
          />
        </Box>
      )}
    </Box>
  )
}

export default UsersDisponibilities
